import React from 'react';
import styled from 'styled-components';
import {
  Typography,
  Container,
  Section,
  DetailsLink,
  ContactForm,
  Figure,
  LinedHeader,
  Box,
  Layout,
  Grid,
} from '../components';
import contactUsImg from '../images/contact/contact.svg';
import { RightArrowIcon } from '../icons';

const Link = styled.a`
  display: flex;
  display: flex;
  align-items: center;
  margin-top: 24px;
  color: #ffffff;
  text-decoration: none;
`;

const ContactPage = () => (
  <Layout title="Contact Us">
    <Container mt={{ _: 8, md: 9 }}>
      <Box p={{ xs: 2, md: 0 }}>
        <Grid gridTemplateColumns={{ _: '1fr', sm: '1fr 1fr 1fr' }}>
          <Box>
            <LinedHeader heading="Address" showRightBorder color="#fbb27e">
              <Typography variant="paragraph2">
                7th Floor, Commercial Cove, House 56/C , Road 132, Dhaka 1212,
                Bangladesh.
              </Typography>
              <Link
                rel="noreferrer"
                target="_blank"
                href="https://goo.gl/maps/JvGVtuz16FDU6WQh6"
              >
                <span>See our Location</span>
                <RightArrowIcon ml={1} fill="#188DCD" />
              </Link>
            </LinedHeader>
          </Box>
          <Box>
            <LinedHeader
              heading="Join our team"
              showRightBorder
              color="#188DCD"
            >
              <Typography variant="paragraph2">
                Do you have a passion for engineering? We’ve been looking for
                you.
              </Typography>
              <DetailsLink variant="arrow" mt={3} to="/careers/">
                Check Opportunities
              </DetailsLink>
            </LinedHeader>
          </Box>
          <Grid item xs={12} md={4}>
            <LinedHeader
              heading="General Inquiries"
              showRightBorder={false}
              color="#D86964"
            >
              <Typography variant="paragraph2" mb={2}>
                We’ve got a nice bit of stories and experts to share their mind.
                Get in touch with us at
              </Typography>
              <Typography variant="paragraph2" color="secondary">
                info@springrain.io
              </Typography>
            </LinedHeader>
          </Grid>
        </Grid>
      </Box>

      <Section p={{ xs: 2, md: 0 }} mt={{ xs: '20px', md: '100px' }}>
        <Grid gridTemplateColumns={{ _: '1fr', sm: '1fr 1fr' }}>
          <Box display={{ _: 'none', sm: 'block' }}>
            <Figure
              src={contactUsImg}
              alt="Let’s talk. Just enter your details and we will reply within 24 hours"
              barColor="#008FD4"
              barPosition="left"
              position="right"
              width={{ xs: '100%', md: '80%' }}
              height="580px"
            />
          </Box>
          <Box>
            <ContactForm showSidebar={false} />
          </Box>
        </Grid>
      </Section>
    </Container>
  </Layout>
);

export default ContactPage;
